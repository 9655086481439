//
//  design2.scss
//

.app-grid {
  display: grid;
  width: 100vw;
  height: 100vh;
  min-height: 512px;
  grid-template-rows: 80px 1fr 32px;
  grid-template-areas:
    "header"
    "content"
    "footer";
  row-gap: 10px;
  padding-bottom: 20px;
}

.app-navbar {
  @extend .d-flex, .align-items-baseline;
  grid-area: header;
  margin: 10px 10px;
}

.app-content {
  @extend .mx-5, .mx-xl-auto;
  grid-area: content;
  max-width: 1024px;
}

.app-footer {
  grid-area: footer;
  margin: 10px 10px;
  font-size: smaller;
}

@include media-breakpoint-up(lg) {
  .app-grid {
    display: grid;
    width: 100vw;
    height: 100vh;
    min-height: 512px;
    grid-template-rows: 80px 1fr 56px;
    grid-template-areas:
      "header"
      "content"
      "footer";
    row-gap: 10px;
  }
}
