//
//  illposed.scss
//
//  Illposed styles

// Default variable overrides
$body-bg: rgb(
  245,
  248,
  246
); // https://www.wildlifeinpixels.net/blog/printing-paper-white/
$body-color: #111;

$font-family-sans-serif: -apple-system, system-ui, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$aa-light-blue: #ceedef;
$aa-light-green: #c8e3cf;
$primary: #00b5c5;

// $light:                $aa-light-blue;
// $light:                rgba($aa-light-blue, 0.3);
$jumbotron-bg: rgba($aa-light-blue, 0.5);

@import "~bootstrap/scss/bootstrap.scss";
@import "./grid.scss";

// hue 246
// https://colorbox.io/?c0=%26p%24s%24%3D11%26p%24h%24st%24%3D271%26p%24h%24e%24%3D0%26p%24h%24c%24%3Deqo%26p%24sa%24st%24%3D0.08%26p%24sa%24e%24%3D1%26p%24sa%24r%24%3D1%26p%24sa%24c%24%3Deqo%26p%24b%24st%24%3D1%26p%24b%24e%24%3D0.2%26p%24b%24c%24%3Deqti%26o%24n%24%3DMix%26o%24ms%24%3D0%2C1%26o%24ro%24%3Dccw&c1=%26p%24s%24%3D11%26p%24h%24st%24%3D240%26p%24h%24e%24%3D240%26p%24h%24c%24%3Deqo%26p%24sa%24st%24%3D0.08%26p%24sa%24e%24%3D1%26p%24sa%24r%24%3D1%26p%24sa%24c%24%3Deqi%26p%24b%24st%24%3D1%26p%24b%24e%24%3D0.36%26p%24b%24c%24%3Deqti%26o%24n%24%3DLavender%26o%24ms%24%3D0%2C1%26o%24ro%24%3Dcw

blockquote {
  @extend .blockquote;
  @extend .mt-0;
}

div .blockquote-footer {
  @extend .blockquote-footer;
  font-style: normal;
  font-size: 100%;
  line-height: 1.3;
  &::before {
    content: "";
  }
}

blockquote p {
  @extend .mb-1;
  font-size: 80%;
  font-style: italic;
  // text-align: start;
  text-align: justify;
  padding: 0em 5px;
  border-left: 5px solid #ccc;
}

strong {
  font-weight: 700;
}

.splash-jumbo {
  @extend .p-5, .mb-4, .rounded-3;
  background-color: $jumbotron-bg;
}

.page-block-title {
  background-color: $jumbotron-bg;
}

.portrait {
  @extend .d-inline-block, .bg-secondary, .border, .rounded-1;
  width: 300px;
  height: 412px;
  --bs-bg-opacity: 0.5;
}

.nav-item {
  @extend .text-decoration-none, .fs-4;
}

.nav-link .active {
  @extend .fw-semibold, .pe-none, .text-body;
}

.nav-link .inactive {
  @extend .text-muted;
  &:hover {
    @extend .text-decoration-underline;
  }
}
